<template>
    <b-row>
        <b-col cols="12" xl="9" md="8">
            <div class="shadow leftCardBottomBorder">
                <div
                    class="w-100 position-relative bg-secondaryColor p-2 text-white d-flex justify-content-between leftCardTopBorder"
                >
                    <p
                        class="mb-0 h2 font-weight-bold"
                        style="color: inherit"
                        v-text="$t(locale.rightSideTitle)"
                    />

                    <img
                        v-for="(data, index) in priceTag"
                        :key="index"
                        width="80px"
                        class="position-absolute"
                        :src="require(`@/assets/duplioAsset/icons/${data.img}`)"
                        :style="data.style"
                    />
                </div>
                <Loading v-if="dataForm.length == 0" />
                <div v-else class="w-100 bg-white p-2 leftCardBottomBorder">
                    <ValidationObserver ref="form">
                        <b-row>
                            <b-col
                                v-for="(item, index) in dataForm"
                                :key="index"
                                :xl="item.xl"
                                :sm="item.sm"
                            >
                                <b-form-group
                                    :label="$t(item.label)"
                                    :label-for="$t(item.label)"
                                >
                                    <v-select
                                        v-if="item.type == 'select'"
                                        :clearable="false"
                                        :options="dataCountry"
                                        label="name"
                                        :placeholder="$t(locale.sendMethod)"
                                        class="per-page-selector-send-method d-inline-block"
                                        v-model="item.value"
                                    >
                                    </v-select>

                                    <ValidationProvider
                                        v-else-if="item.type == 'email'"
                                        #default="{errors}"
                                        :name="$t(item.label)"
                                        vid="email"
                                        rules="required|email"
                                    >
                                        <b-form-input
                                            v-model="item.value"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            :placeholder="$t(item.label)"
                                        />
                                        <small
                                            class="text-danger"
                                            v-text="errors[0]"
                                        />
                                    </ValidationProvider>

                                    <ValidationProvider
                                        v-else
                                        #default="{errors}"
                                        :name="$t(item.label)"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :id="$t(item.label)"
                                            :placeholder="$t(item.label)"
                                            v-model="item.value"
                                        />
                                        <small
                                            class="text-danger"
                                            v-text="errors[0]"
                                        />
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </ValidationObserver>

                    <div class="d-flex justify-content-end">
                        <b-button
                            @click="updateBillingDetails"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                        >
                            <span v-if="updateDetails">
                                <b-spinner small type="grow"></b-spinner>
                                {{ $t(locale.loading) }}...
                            </span>

                            <span v-else v-text="$t(locale.updateBtn)" />
                        </b-button>
                    </div>
                </div>
            </div>
        </b-col>
        <b-col cols="12" md="4" xl="3">
            <CurrentPlan />
        </b-col>

        <b-col cols="12">
            <b-card class="mt-2 rounded-lg">
                <div class="mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            md="4"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            style="gap: 1rem"
                        >
                            <p class="mb-0" v-text="$t(locale.filter)" />

                            <v-select
                                v-model="selected"
                                label="label"
                                :reduce="label => label.value"
                                :options="$t(locale.status)"
                                :clearable="false"
                                placeholder="Filter"
                                @input="filterStatus($event)"
                                class="per-page-selector d-inline-block"
                            >
                            </v-select>
                        </b-col>

                        <b-col
                            cols="12"
                            md="3"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            style="gap: 0.5rem"
                        >
                            <b-form-input
                                v-model="searchQuery"
                                placeholder="Search..."
                                style="width: 190px"
                            />
                        </b-col>
                    </b-row>
                </div>
                <!-- Table -->
                <b-table
                    ref="table"
                    responsive
                    :items="items"
                    :fields="tableColumns"
                    primary-key="id"
                    show-empty
                    :sort-desc.sync="sortDesc"
                    :sort-by.sync="sortBy"
                    :empty-text="$t(locale.notMatchRecord)"
                    class="position-relative"
                >
                    <template #head(companyName)="data">
                        <div class="d-flex">
                            <span class="my-auto" style="margin-right: 5px">
                                {{ $t(data.field.label) }}
                            </span>

                            <span class="d-flex flex-column position-relative">
                                <feather-icon
                                    size="10"
                                    @click="sortingChanged(data, true)"
                                    class="cursor-pointer"
                                    icon="ChevronUpIcon"
                                    style="pointer: cursor"
                                    role="button"
                                />

                                <feather-icon
                                    @click="sortingChanged(data, false)"
                                    size="10"
                                    class="cursor-pointer"
                                    icon="ChevronDownIcon"
                                    role="button"
                                />
                            </span>
                        </div>
                    </template>
                    <template #head(invoiceNumber)="data">
                        <div class="d-flex">
                            <span class="my-auto" style="margin-right: 5px">
                                {{ $t(data.field.label) }}
                            </span>

                            <span class="d-flex flex-column position-relative">
                                <feather-icon
                                    size="10"
                                    @click="sortingChanged(data, true)"
                                    class="cursor-pointer"
                                    icon="ChevronUpIcon"
                                    style="pointer: cursor"
                                    role="button"
                                />

                                <feather-icon
                                    @click="sortingChanged(data, false)"
                                    size="10"
                                    class="cursor-pointer"
                                    icon="ChevronDownIcon"
                                    role="button"
                                />
                            </span>
                        </div>
                    </template>
                    <template #head(amountDue)="data">
                        <div class="d-flex">
                            <span class="my-auto" style="margin-right: 5px">
                                {{ $t(data.field.label) }}
                            </span>

                            <span class="d-flex flex-column position-relative">
                                <feather-icon
                                    size="10"
                                    @click="sortingChanged(data, true)"
                                    class="cursor-pointer"
                                    icon="ChevronUpIcon"
                                    style="pointer: cursor"
                                    role="button"
                                />

                                <feather-icon
                                    @click="sortingChanged(data, false)"
                                    size="10"
                                    class="cursor-pointer"
                                    icon="ChevronDownIcon"
                                    role="button"
                                />
                            </span>
                        </div>
                    </template>
                    <template #head(date)="data">
                        <div class="d-flex">
                            <span class="my-auto" style="margin-right: 5px">
                                {{ $t(data.field.label) }}
                            </span>

                            <span class="d-flex flex-column position-relative">
                                <feather-icon
                                    size="10"
                                    @click="sortingChanged(data, true)"
                                    class="cursor-pointer"
                                    icon="ChevronUpIcon"
                                    style="pointer: cursor"
                                    role="button"
                                />

                                <feather-icon
                                    @click="sortingChanged(data, false)"
                                    size="10"
                                    class="cursor-pointer"
                                    icon="ChevronDownIcon"
                                    role="button"
                                />
                            </span>
                        </div>
                    </template>
                    <template #head(dueDate)="data">
                        <div class="d-flex">
                            <span class="my-auto" style="margin-right: 5px">
                                {{ $t(data.field.label) }}
                            </span>

                            <span class="d-flex flex-column position-relative">
                                <feather-icon
                                    size="10"
                                    @click="sortingChanged(data, true)"
                                    class="cursor-pointer"
                                    icon="ChevronUpIcon"
                                    style="pointer: cursor"
                                    role="button"
                                />

                                <feather-icon
                                    @click="sortingChanged(data, false)"
                                    size="10"
                                    class="cursor-pointer"
                                    icon="ChevronDownIcon"
                                    role="button"
                                />
                            </span>
                        </div>
                    </template>
                    <template #head(status)="data">
                        <div class="d-flex">
                            <span class="my-auto" style="margin-right: 5px">
                                {{ $t(data.field.label) }}
                            </span>

                            <span class="d-flex flex-column position-relative">
                                <feather-icon
                                    size="10"
                                    @click="sortingChanged(data, true)"
                                    class="cursor-pointer"
                                    icon="ChevronUpIcon"
                                    style="pointer: cursor"
                                    role="button"
                                />

                                <feather-icon
                                    @click="sortingChanged(data, false)"
                                    size="10"
                                    class="cursor-pointer"
                                    icon="ChevronDownIcon"
                                    role="button"
                                />
                            </span>
                        </div>
                    </template>
                    <template #head(file)="data">
                        <div class="d-flex">
                            <span class="my-auto" style="margin-right: 5px">
                                {{ $t(data.field.label) }}
                            </span>

                            <span class="d-flex flex-column position-relative">
                                <feather-icon
                                    size="10"
                                    @click="sortingChanged(data, true)"
                                    class="cursor-pointer"
                                    icon="ChevronUpIcon"
                                    style="pointer: cursor"
                                    role="button"
                                />

                                <feather-icon
                                    @click="sortingChanged(data, false)"
                                    size="10"
                                    class="cursor-pointer"
                                    icon="ChevronDownIcon"
                                    role="button"
                                />
                            </span>
                        </div>
                    </template>
                    <!-- Column: date -->
                    <template #cell(date)="data">
                        <div class="d-flex">
                            <p :class="[data.item.classText, 'mb-0']">
                                {{ data.value }}
                            </p>
                        </div>
                    </template>

                    <template #cell(companyName)="data">
                        <div class="d-flex">
                            <p :class="[data.item.classText, 'mb-0']">
                                {{ data.value }}
                            </p>
                        </div>
                    </template>

                    <template #cell(invoiceNumber)="data">
                        <div class="d-flex">
                            <p :class="[data.item.classText, 'mb-0']">
                                {{ data.value }}
                            </p>
                        </div>
                    </template>

                    <template #cell(amountDue)="data">
                        <div class="d-flex">
                            <p :class="[data.item.classText, 'mb-0']">
                                {{ data.value }} SEK
                            </p>
                        </div>
                    </template>

                    <template #cell(dueDate)="data">
                        <div class="d-flex">
                            <p :class="[data.item.classText, 'mb-0']">
                                {{ data.value }}
                            </p>
                        </div>
                    </template>

                    <template #cell(status)="data">
                        <div class="d-flex">
                            <p :class="[data.item.classText, 'mb-0']">
                                {{ renderStatus(getLang, data.value) }}
                            </p>
                        </div>
                    </template>

                    <!-- Column: date -->
                    <template #cell(file)="data">
                        <b-button
                            variant="transparent"
                            @click="installFile(data.value)"
                        >
                            <feather-icon size="16" icon="DownloadIcon" />
                        </b-button>
                    </template>
                </b-table>

                <div class="mx-2 mb-2">
                    <b-row align-h="end">
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="pagination.totalBilling"
                                :per-page="pagination.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required, email} from '@validations';
import CurrentPlan from '@/@core/components/billings/CurrentPlan.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import countriesCode from '@/mixins/countriesCode.json';

export default {
    directives: {
        Ripple,
    },
    components: {
        vSelect,
        CurrentPlan,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            dataForm: [],
            updateDetails: false,
            selected: 'All',
            searchQuery: '',
            tableColumns: [
                {
                    key: 'companyName',
                    label: 'BillingPageCard.companyHead',
                },
                {
                    key: 'invoiceNumber',
                    label: 'BillingPageCard.invoiceHead',
                },
                {
                    key: 'amountDue',
                    label: 'BillingPageCard.amountHead',
                },
                {
                    key: 'date',
                    label: 'BillingPageCard.dateHead',
                },
                {
                    key: 'dueDate',
                    label: 'BillingPageCard.dueDateHead',
                },
                {
                    key: 'status',
                    label: 'BillingPageCard.statusHead',
                },
                {
                    key: 'file',
                    label: 'BillingPageCard.fileHead',
                    tdClass: 'bTableThStyle',
                    thStyle: {width: '20px'},
                },
            ],
            items: [],
            currentPage: 1,
            pagination: {
                totalBilling: '',
                perPage: 20,
            },
            priceTag: [
                {
                    img: 'priceTagUp.png',
                    style: {right: '5px', top: 0},
                },
                {
                    img: 'priceTagDown.png',
                    style: {right: '54px', top: '11px'},
                },
            ],
            config: useAppConfig(),
            sortBy: '',
            sortDesc: null,
            base64: '',
            dataCountry: countriesCode,
            required,
            email,
            locale: {
                rightSideTitle: 'BillingPageCard.rightSide.title',
                sendMethod: 'SettingPageCard.placeHolder.chooceSendMethod',
                loading: 'Message.loading',
                updateBtn: 'BillingPageCard.leftSide.updateBtn',
                filter: 'BillingPageCard.Filter',
                notMatchRecord: 'EventLogCard.noMatchRecordFound',
                status: 'BillingPageCard.status',
            },
        };
    },

    watch: {
        searchQuery(value) {
            let dataArray = [];

            this.$useJwt
                .getBillingsList({
                    params: {
                        status: this.selected == 'All' ? '' : this.selected,
                        company: value,
                    },
                })
                .then(res => {
                    const {results} = res.data;

                    this.pagination.totalBilling = res.data.count;

                    this.renderTable(results);
                });

            this.items = dataArray;
        },

        currentPage(value) {
            this.getBillingLists(value);
        },
    },

    computed: {
        getLang() {
            return this.config.lang.value;
        },
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
    },

    methods: {
        renderStatus(language, valueResource) {
            let resource = this.$t('BillingPageCard.status').find(
                element => element.value == valueResource
            );

            return resource.label;
        },
        renderCountries(params) {
            return this.dataCountry.find(element => element.iso === params);
        },
        renderTable(value) {
            let dataArray = [];
            this.items = [];
            value.forEach(element => {
                dataArray.push({
                    id: element.id,
                    companyName: element.company,
                    invoiceNumber: element.invoice_number,
                    dueDate: element.due_date,
                    status: element.status,
                    date: element.invoice_date,
                    classText: this.statusClass(element.status),
                    file: {file: element.file, id: element.id},
                    amountDue: element.amount_due,
                });
            });

            this.items = dataArray;
        },
        statusClass(status) {
            switch (status.toLowerCase()) {
                case 'unpaid':
                    return 'text-warning';
                case 'overdue':
                    return 'text-danger';
                case 'paid':
                    return 'text-success';
            }
        },
        sortingChanged(sortBy, desc) {
            this.sortBy = sortBy.column;

            this.sortDesc = desc;
        },
        getBillingLists(page) {
            this.$useJwt
                .getBillingsList({
                    params: {page: page},
                })
                .then(res => {
                    const {results} = res.data;
                    this.pagination.totalBilling = res.data.count;

                    this.renderTable(results);
                });
        },
        getBillingDetails() {
            this.dataForm = [];
            this.$useJwt.getBillingsDetail().then(res => {
                const {data} = res.data;

                this.dataForm.push(
                    {
                        label: 'BillingPageCard.leftSide.Form.invoiceAddress',
                        object: 'address',
                        xl: '3',
                        sm: '6',
                        type: 'input',
                        value: data.address,
                    },
                    {
                        label: 'BillingPageCard.leftSide.Form.city',
                        object: 'city',
                        xl: '3',
                        sm: '6',
                        type: 'input',
                        value: data.city,
                    },
                    {
                        label: 'BillingPageCard.leftSide.Form.zipCode',
                        object: 'zip_code',
                        xl: '3',
                        sm: '6',
                        type: 'input',
                        value: data.zip_code,
                    },
                    {
                        label: 'BillingPageCard.leftSide.Form.billingEmail',
                        object: 'billing_email',
                        xl: '3',
                        sm: '6',
                        type: 'email',
                        value: data.billing_email,
                    },
                    {
                        label: 'BillingPageCard.leftSide.Form.country',
                        object: 'country',
                        xl: '4',
                        sm: '6',
                        type: 'select',
                        value: this.renderCountries(data.country),
                    }
                );
            });
        },
        installFile(value) {
            this.$useJwt.downLoadFile(value.id).then(res => {
                const {base64_string} = res.data;
                const {file_name} = res.data;

                const linkSource = `data:application/pdf;base64,${base64_string}`;
                const downloadLink = document.createElement('a');
                const fileName = file_name;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            });
        },
        filterStatus(status) {
            if (status == 'All') status = '';

            this.$useJwt
                .getBillingsList({
                    params: {
                        status: status,
                    },
                })
                .then(res => {
                    const {results} = res.data;

                    this.pagination.totalBilling = res.data.count;

                    this.renderTable(results);
                });
        },
        updateBillingDetails() {
            this.$refs.form.validate().then(res => {
                if (res) {
                    let newBillingsDetails = {};

                    this.dataForm.forEach(element => {
                        if (element.object == 'country') {
                            newBillingsDetails.country = element.value.iso;
                        } else {
                            newBillingsDetails[element.object] = element.value;
                        }
                    });

                    this.updateDetails = true;

                    this.$useJwt
                        .updateBillingsDetail(newBillingsDetails)
                        .then(res => {
                            this.popupMsg(
                                this.$t('Message.Success'),
                                res.data[this.message],
                                'CheckIcon',
                                'success'
                            );
                        })
                        .finally(() => {
                            this.updateDetails = false;
                        });
                }
            });
        },

        init() {
            this.getBillingLists();
            this.getBillingDetails();
        },
    },

    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.per-page-selector {
    width: 200px;
}
.leftCardTopBorder {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important;
}

.leftCardBottomBorder {
    border-bottom-left-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important;
}

.per-page-selector-send-method {
    width: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
