<template>
    <b-card
        bg-variant="secondaryColor"
        text-variant="white"
        class="rounded-lg text-white"
    >
        <template v-if="currentPlan.length > 0">
            <b-card-title class="text-white">
                {{ $t('BillingPageCard.rightSide.title') }}
            </b-card-title>

            <b-card-text v-for="(data, index) in currentPlan" :key="index">
                <p class="font-weight-bold">{{ data.plan_name }}</p>
                <p>
                    {{
                        $t('BillingPageCard.invoiceNumber', {
                            number: data.invoiceNumbers,
                        })
                    }}
                </p>
                <p class="ml-2">
                    {{ data.price }} SEK / {{ $t('Message.month') }}
                </p>
            </b-card-text>

            <b-link :to="{name: 'subscription'}" class="">
                {{ $t('BillingPageCard.rightSide.planLink') }} >>
            </b-link>
        </template>

        <Loading v-else />
    </b-card>
</template>

<script>
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
export default {
    mixins: [getSubscribersMixins],
    name: 'CurrentPlan',
    data() {
        return {
            currentPlan: [],
        };
    },

    methods: {
        async renderCurrentPlan() {
            let currentPlanId = await this.getCurrentPlan();

            let paymentPlan = await this.getPaymentPlan();

            this.$useJwt.packages().then(res => {
                const {results} = res.data;

                let getCurrentPlan = results.filter(element => {
                    if (element.package !== null) {
                        return element.package.id == currentPlanId;
                    }
                });

                getCurrentPlan.forEach(element => {
                    this.currentPlan.push({
                        plan_name: element.package.package_name,
                        price:
                            paymentPlan[0] == 'Quarterly'
                                ? element.quarterly_base_price_monthly_average
                                : element.yearly_base_price_monthly_average,
                        invoiceNumbers: element.number_of_invoices_per_month,
                        paymentPlan: paymentPlan[0],
                    });
                });
            });
        },
    },

    mounted() {
        this.renderCurrentPlan();
    },
};
</script>

<style></style>
